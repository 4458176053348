<template>
  <div
    class="seed-card"
    :class="mainCss"
    @click="viewSeed"
    v-on-clickaway="clickAway"
  >
    <div class="header-icons">
      <span class="icon-favorite" v-if="isFavorite"></span>
      <span class="icon-type" v-if="isUnread"></span>
    </div>
    <div class="origin-group" v-show="!picker && value">
      <div class="origin">{{ originText }}</div>
      <div>{{ originValue }}</div>
    </div>
    <div class="type" v-html="typeText"></div>
    <div class="text">{{ contentText }}</div>
    <div v-if="!picker" class="tags">
      <TagGroup read dark v-model="this.value.tags"></TagGroup>
    </div>
    <div class="buttons actions is-right" v-if="!noActions">
      <a
        class="button is-icon has-text-primary"
        @click.stop="favoriteCard(value.id)"
      >
        <span class="icon-favorite"></span>
      </a>

      <a
        class="button is-icon has-text-primary"
        @click.stop="tryDelete(value.id)"
      >
        <span class="icon-trash"></span>
      </a>
    </div>

    <!-- @mouseout="showDeleteConfirm = ''" -->
    <div
      class="confirm-delete"
      v-if="showDeleteConfirm"
      @click.stop="showDeleteConfirm = ''"
    >
      <a
        class="button is-white has-text-danger"
        @click.stop="deleteCard(showDeleteConfirm)"
      >
        <span>Confirm delete</span>
        <span class="icon-trash-open"></span>
      </a>
    </div>
  </div>
</template>

<script>
import TagGroup from "@/components/TagGroup.vue";
import Cards from "../mixins/Cards.js";
import { mixin as clickaway } from "vue-clickaway";

export default {
  name: "SeedCard",
  components: {
    TagGroup
  },
  mixins: [Cards, clickaway],
  props: {
    type: {
      type: String,
      default: "start",
      required: true
    },
    text: {
      type: String
    },
    origin: {
      type: String /* received or sent */
    },
    picker: {
      type: Boolean,
      default: false
    },
    noActions: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      showDeleteConfirm: "",
      typeTexts: {
        start:
          "Give a feedback about a thing that your colleague needs to start doing in order to continue growing. E.g. 'Improving your presentation skills'",
        stop:
          "Give a feedback about something that your colleague does and needs to be weeded out. E.g. 'Being late to meetings'",
        continue:
          "Give a feedback about something that your colleague already does well and just needs to water it. E.g. 'Making the right questions at the right time'"
      }
    };
  },
  computed: {
    isUnread() {
      if (this.origin == "send") {
        return false;
      } else {
        if (this.value) return Boolean(this.value.unread);
      }
      return false;
    },
    isFavorite() {
      if (!this.value) return false;

      let favorites = this.$store.getters["favorites/list"];
      for (let i = 0; i < favorites.length; i++) {
        if (favorites[i].id == this.value.id) {
          return true;
        }
      }

      return false;
    },
    mainCss() {
      let css = "is-" + this.type + " is-" + this.origin;
      if (this.picker) {
        css += " is-picker ";
      }
      return css;
    },
    typeText() {
      return "Seed<br>to " + this.type;
    },
    contentText() {
      if (this.picker) return this.typeTexts[this.type];
      else {
        if (!this.text) return "";

        if (this.text.length < 200) return this.text;
        else {
          var trimmedString = this.text.substr(0, 200);
          return (
            trimmedString.substr(
              0,
              Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
            ) + "..."
          );
        }
      }
    },
    originText() {
      if (this.origin == "received") return "From";
      else return "To";
    },
    originValue() {
      if (this.value) {
        if (this.origin == "received")
          return this.convertUserIdIntoName(this.value.from_id);
        else {
          let tos = [];
          if (this.value.tos) {
            for (let i = 0; i < this.value.tos.length; i++) {
              tos.push(this.convertUserIdIntoName(this.value.tos[i]));
            }
          }

          return tos.join(",");
        }
      } else return "";
    }
  },
  methods: {
    clickAway() {
      this.showDeleteConfirm = "";
    },
    viewSeed() {
      if (!this.picker /*&& this.origin == "received"*/)
        this.$router.push("/seed/" + this.value.id);
    },
    tryDelete(id) {
      this.showDeleteConfirm = id;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.seed-card {
  width: 280px;
  //height: 360px;
  border-top: solid 12px;
  background: #f7f7f7;
  align-self: center;
  text-align: left;
  padding: 20px;
  position: relative;
  cursor: pointer;

  @include ay-desktop {
    width: 250px;
  }

  @include ay-tablet {
    width: 220px;
  }

  @include ay-mobile {
    width: 290px;
  }

  .header-icons {
    position: absolute;
    right: 5px;
    top: 5px;
    color: #c9c9c9;

    span {
      margin-left: 7px;
      font-size: 14px;
    }
  }

  &:hover {
    .actions {
      pointer-events: auto;
      opacity: 1;
    }
  }

  &.is-picker {
    width: 420px;
    height: 600px;
    border-top: solid 18px;
    padding: 60px;

    .type {
      font-size: 52px;
      padding-bottom: 10px;

      @media screen and (max-width: 1440px) {
        font-size: 48px;
      }
    }

    .text {
      font-size: 28px;

      @media screen and (max-width: 1440px) {
        font-size: 24px;
        line-height: 1.375em;
      }
    }

    .actions {
      display: none;
    }
  }

  .type {
    font-size: 38px;
    line-height: 0.75em;
    font-weight: $weight-medium;

    @include ay-tablet {
      font-size: 29px;
    }
  }

  .text {
    font-size: 20px;
    word-break: break-word;

    @include ay-tablet {
      font-size: 18px;
    }
  }

  &.is-start {
    border-color: #15b69f;

    .header-icons {
      .icon-type {
        color: #15b69f;
      }
    }
  }

  &.is-stop {
    border-color: $danger-color;

    .header-icons {
      .icon-type {
        color: $danger-color;
      }
    }
  }

  &.is-continue {
    border-color: $info-color;

    .header-icons {
      .icon-type {
        color: $info-color;
      }
    }
  }

  // &.is-received {
  //   cursor: pointer;
  // }

  .origin-group {
    padding-bottom: 15px;
    font-size: 13px;
    font-weight: 300;

    .origin {
      font-weight: $weight-bold;
    }
  }

  .tags {
    margin-top: 20px;
  }

  .actions {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 85px;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2805497198879552) 0%,
      rgba(0, 0, 0, 0.014443277310924318) 66%
    );
    padding-top: 12px;
    padding-right: 10px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 400ms ease;
    margin-bottom: 0;
  }

  .confirm-delete {
    position: absolute;
    top: -12px;
    left: 0;
    width: 100%;
    height: calc(100% + 12px);
    background: rgba(245, 0, 0, 0.6);
    padding: 14px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .button {
      width: 100%;

      .icon-trash-open {
        position: absolute;
        right: 10px;
      }
    }
  }
}
</style>
