<template>
  <div class="page-settings">
    <app-header />
    <div class="update-success-alert" v-show="savingSuccess">
      <span>Profile updated successfully</span>
      <span class="icon"><span class="icon-check"></span></span>
    </div>
    <div class="update-error-alert" v-show="error">
      <span>{{ error }}</span>
    </div>
    <section class="section">
      <div class="container">
        <h1 class="title1">Settings</h1>
        <p class="lead">
          Here you can edit your profile informations, change your e-mail and
          password, and enable notifications.
        </p>
        <form @submit.prevent="save">
          <div class="section-title">
            <span class="icon-account"></span>
            <span>
              Account
            </span>
          </div>
          <div class="field avatar-field">
            <figure class="avatar">
              <template v-if="avatar !== ''">
                <img :src="avatar" alt="" />
                <button
                  type="button"
                  @click="$set(user, 'avatar', '')"
                  class="button is-danger is-small is-icon is-rounded"
                >
                  <span class="icon-cross"></span>
                </button>
              </template>
              <div class="no-image-text" v-else>
                {{ userInitials }}
              </div>
            </figure>
            <div class="control">
              <el-upload
                class="upload-demo"
                :action="uploadEndpoint"
                :data="avatarData"
                :on-success="handleAvatarSuccess"
                :headers="avatarHeaders"
                :show-file-list="false"
                accept="image/jpeg, image/png"
              >
                <button type="button" class="button is-black">
                  Upload photo
                </button>
                <div slot="tip" class="el-upload__tip">
                  Minimum size is 256x256px PNG or JPG file.
                </div>
              </el-upload>
            </div>
          </div>

          <div class="columns">
            <div class="column is-6">
              <div class="field">
                <label class="label"> First name</label>
                <div class="control">
                  <input type="text" v-model="user.first" class="input" />
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="field">
                <label class="label">Last name</label>
                <div class="control">
                  <input type="text" v-model="user.last" class="input" />
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <label class="label">Email</label>
            <div class="control">
              <input type="email" v-model="user.email" class="input" disabled />
            </div>
          </div>

          <div class="field is-hidden">
            <label class="label">Position</label>
            <div class="control">
              <el-select v-model="user.position">
                <el-option
                  v-for="item in roles"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  class="select-item"
                  ><span>{{ item.name }}</span>
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="section-title">
            <span class="icon-account"></span>
            <span>
              Security
            </span>
          </div>

          <div class="field">
            <label class="label">Old Password</label>
            <password-field v-model="oldPassword"></password-field>
          </div>

          <div class="field">
            <label class="label">New Password</label>
            <password-field v-model="newPassword"></password-field>
            <p class="help">Must be at least 8 characters.</p>
          </div>

          <div class="field">
            <label class="label">Confirm New Password</label>
            <password-field v-model="confirmNewPassword"></password-field>
            <p class="help">Must be at least 8 characters.</p>
          </div>

          <div class="section-title">
            <span class="icon-bell"></span>
            <span>
              Notifications
            </span>
          </div>

          <div class="notification-group">
            <div class="group-title">
              <span>E-mail notifications</span>
              <!--span class="toggle-all">
                Toggle all
              </span-->
            </div>
            <div class="field">
              <div class="control">
                <label class="checkbox">
                  <input type="checkbox" v-model="user.email_seedback" />
                  Seedback Comunications
                </label>
              </div>
            </div>

            <div class="field">
              <div class="control">
                <label class="checkbox">
                  <input type="checkbox" v-model="user.email_account" />
                  Account Activity
                </label>
              </div>
            </div>
          </div>

          <!-- <div class="notification-group">
            <div class="group-title">
              <span>Browser notifications</span>
              <span class="toggle-all">
                Toggle all
              </span>
            </div>
            <div class="field">
              <div class="control">
                <label class="checkbox">
                  <input type="checkbox" />
                  Seedback Comunications
                </label>
              </div>
            </div>

            <div class="field">
              <div class="control">
                <label class="checkbox">
                  <input type="checkbox" />
                  Account Activity
                </label>
              </div>
            </div>
          </div> -->

          <div class="field is-grouped is-grouped-right">
            <div class="control">
              <button type="submit" class="button button-submit is-dark">
                Save profile
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
    <app-footer></app-footer>
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppFooter.vue";
import PasswordField from "../components/PasswordField.vue";

export default {
  components: { AppHeader, AppFooter, PasswordField },
  name: "Settings",
  data() {
    return {
      user: {
        first: "",
        last: "",
        email: "",
        position: "",
        email_seedback: 1,
        email_account: 1
      },
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      roles: [],
      error: "",
      savingSuccess: false
    };
  },
  computed: {
    avatar() {
      if (this.user.avatar) {
        return process.env.VUE_APP_ASSETS_PATH + this.user.avatar;
      } else {
        return "";
      }
    },
    uploadEndpoint() {
      return process.env.VUE_APP_BACKEND_API + "upload";
    },
    avatarData() {
      return {
        schema: "User",
        field: "avatar"
      };
    },
    avatarHeaders() {
      return {
        Authorization: "Bearer " + localStorage.getItem("authToken")
      };
    },
    userInitials() {
      return (
        this.user.first.charAt(0) + this.user.last.charAt(0)
      ).toUpperCase();
    }
  },
  mounted() {
    this.axiosRequest(
      "get",
      'comboboxdata?data={"schemaID":"1","fieldKey":"position"}'
    )
      .then((response) => {
        this.roles = response.data;
        this.roles.map((item) => {
          item.id = item.id.toString();
        });
        this.get();
      })
      .catch((error) => {
        console.error(error);
      });
  },
  methods: {
    get() {
      this.axiosRequest("get", "my-settings")
        .then((response) => {
          console.log(response.data);
          this.user = response.data.user;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    handleAvatarSuccess(res) {
      console.log("res", res);
      this.$set(this.user, "avatar", res.name);
      //TODO call upload endpoint that receives schema=User, field=avatar, and file=binary_chunk
      //it returns the new path that you should send on the save profile
    },
    save() {
      if (this.newPassword != "") {
        if (this.newPassword != this.confirmNewPassword) {
          this.error = "Passwords do not match.";
          setTimeout(() => {
            this.error = "";
          }, 2500);
          return;
        } else if (this.newPassword.length < 8) {
          this.error = "Your new password should have at least 8 characters";
          setTimeout(() => {
            this.error = "";
          }, 2500);
          return;
        }
      }
      this.axiosRequest("put", "my-settings", {
        name: this.user.first + " " + this.user.last,
        // email: this.user.email,
        position: this.user.position,
        old_password: this.oldPassword,
        new_password: this.newPassword,
        email_seedback: this.user.email_seedback,
        email_account: this.user.email_account,
        avatar: this.user.avatar //if have changed send the upload method result
      })
        .then((response) => {
          console.log(response.data);
          this.savingSuccess = true;

          setTimeout(() => {
            this.savingSuccess = false;
          }, 1500);

          let currentUser = this.$store.getters["users/byId"](
            localStorage.getItem("userID")
          );
          currentUser.name = this.user.first + " " + this.user.last;
          currentUser.avatar = this.user.avatar;
          for (let i = 0; i < this.roles.length; i++) {
            if (this.roles[i].id == this.user.position) {
              currentUser.position = this.roles[i];
              break;
            }
          }

          this.$store.commit("users/edit", currentUser);
          //TODO live update from the avatar too?
        })
        .catch((error) => {
          console.error(error);

          this.error = error.response.data.message;
          setTimeout(() => {
            this.error = "";
          }, 2500);
        });
    }
  }
};
</script>

<style lang="scss">
.page-settings {
  text-align: left;

  .right-menu-side {
    .button-filters,
    .button-favorite {
      display: none;
    }
  }

  .section > .container {
    max-width: 580px;
  }

  .section-title {
    font-size: 30px;
    color: #3c3c3c;
    font-weight: $weight-medium;
    display: flex;
    align-items: center;
    margin-top: 50px;

    margin-bottom: 20px;
    [class^="icon-"] {
      font-size: 16px;
      margin-right: 10px;
    }
  }
  .footer {
    position: static;
  }
  .label {
    color: #838383;
    font-weight: $weight-regular;
  }
  .input
  // .select select 
 {
    background-color: #efefef;
    box-shadow: none;
    border: none;

    &:focus {
      border-color: $primary;
    }
  }

  .el-select {
    width: 100%;
    background-color: #efefef;

    .el-input__inner {
      background-color: #efefef;
      border: none;
    }

    //  .el-input.is-focus .el-input__inner {
    //    border-color: $primary;
    //  }
  }
  // .select {
  //   &,
  //   select {
  //     width: 100%;
  //   }
  // }

  .notification-group {
    margin-bottom: 20px;

    .group-title {
      font-size: 18px;
      font-weight: $weight-medium;
      border-bottom: 2px solid #3c3c3c;
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      .toggle-all {
        font-size: 12px;
        color: $primary;
        margin-left: auto;
        font-weight: $weight-regular;
        cursor: pointer;
      }
    }

    .checkbox {
      display: flex;
      align-items: center;
      input[type="checkbox"] {
        margin-right: 6px;
      }
      //   &:not(:last-child) {
      //     margin-bottom: 15px;
    }
    // }
  }
  .button-submit {
    margin-top: 50px;
  }
  .avatar-field {
    display: flex;
    align-items: center;
    .avatar {
      width: 130px;
      height: 130px;
      border-radius: 130px;
      margin-right: 25px;
      background-color: $primary;
      position: relative;

      @include ay-mobile {
        width: 100px;
        height: 100px;
        border-radius: 100px;
      }

      img {
        border-radius: 140px;
        width: 130px;
        height: 130px;

        @include ay-mobile {
          width: 100px;
          height: 100px;
          border-radius: 100px;
        }
      }

      .button {
        position: absolute;
        height: 20px;
        width: 20px;
        font-size: 8px;
        top: 0;
        right: 20px;
      }
      .no-image-text {
        font-size: 70px;
        color: white;
        opacity: 0.4;
        font-weight: $weight-bold;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
    }
  }
  .update-success-alert,
  .update-error-alert {
    background-color: $primary;
    color: white;
    display: flex;
    align-items: center;
    height: 50px;
    justify-content: center;
    position: sticky;
    top: 0;
    z-index: 10;
    .icon {
      width: 15px;
      height: 15px;
      border-radius: 15px;
      background-color: white;
      margin-left: 12px;
    }
  }

  .update-error-alert {
    background-color: $danger-color;
  }
}

.el-scrollbar .popper__arrow::after {
  display: none !important;
}
</style>
