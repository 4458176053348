<template>
  <div class="tag-group" :class="mainCSS">
    <a
      class="button"
      :class="{ 'is-deselected': !isActive(1) }"
      v-if="isVisible(1)"
      @click="toogleTag(1)"
    >
      <img src="../assets/icons/tags/meetings.svg" alt="" />
      <span>Meetings</span>
    </a>

    <a
      class="button"
      :class="{ 'is-deselected': !isActive(2) }"
      v-if="isVisible(2)"
      @click="toogleTag(2)"
    >
      <img src="../assets/icons/tags/process.svg" alt="" />
      <span>Work Process</span>
    </a>

    <a
      class="button"
      :class="{ 'is-deselected': !isActive(3) }"
      v-if="isVisible(3)"
      @click="toogleTag(3)"
    >
      <img src="../assets/icons/tags/event.svg" alt="" />
      <span>Events</span>
    </a>

    <a
      class="button"
      :class="{ 'is-deselected': !isActive(4) }"
      v-if="isVisible(4)"
      @click="toogleTag(4)"
    >
      <img src="../assets/icons/tags/social.svg" alt="" />
      <span>Social</span>
    </a>

    <a
      class="button"
      v-if="isVisible(5)"
      :class="{ 'is-deselected': !isActive(5) }"
      @click="toogleTag(5)"
    >
      <img src="../assets/icons/tags/organization.svg" alt="" />
      <span>Organization</span>
    </a>
  </div>
</template>

<script>
export default {
  name: "TagGroup",
  props: {
    dark: {
      type: Boolean,
      default: false
    },
    read: {
      type: Boolean,
      default: false
    },
    white: {
      type: Boolean,
      default: false
    },
    icon: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      tags: []
    };
  },
  computed: {
    mainCSS() {
      let css = "";
      if (this.dark) css += " is-dark";
      if (this.read) css += " is-read";
      if (this.white) css += " is-white";
      if (this.icon) css += " is-icon";
      return css;
    }
  },
  methods: {
    isActive(tag) {
      if (this.read) return true;
      else return this.value.includes(tag);
    },
    isVisible(tag) {
      if (this.read) {
        return this.value.includes(tag);
      } else {
        return true;
      }
    },
    toogleTag(tag) {
      if (this.value.includes(tag)) {
        for (let i = 0; i < this.value.length; i++) {
          if (this.value[i] == tag) {
            this.value.splice(i, 1);
            break;
          }
        }
      } else {
        this.value.push(tag);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.tag-group {
  &.is-icon {
    .button {
      border: none;
      background: transparent;
      padding: 0 5px;
      span {
        display: none;
      }
    }
  }

  &.is-read {
    .button {
      pointer-events: none;
      cursor: auto;
    }
  }

  &.is-dark {
    .button {
      pointer-events: none;
      cursor: auto;
      background: #e0e0e0;
      border-color: #e0e0e0;
      color: $dark;

      img {
        filter: brightness(0);
        opacity: 0.7;
      }
    }
  }

  &.is-white {
    .button {
      pointer-events: none;
      cursor: auto;
      background: #ffffff;
      border-color: #ffffff;
      color: $dark;

      img {
        filter: brightness(0);
        opacity: 0.7;
      }
    }
  }

  .button {
    border-radius: 4px;
    background: transparent;
    color: #ffffff;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 0px 10px;
    font-size: 13px;
    height: 28px;
    border-color: #ffffff;

    &.is-deselected {
      opacity: 0.4;
    }

    &:hover {
      opacity: 1;
    }
  }
  img {
    height: 14px;
    margin-right: 5px;
    filter: brightness(0) invert(1);
  }
}
</style>
