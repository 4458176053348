<template>
  <div class="page-view-seed">
    <div class="page-content">
      <div class="left-side">
        <a
          class="button is-icon button-back"
          :class="{ 'is-disabled': indexSelected == 1 }"
          @click="moveDeck(-1)"
        >
          <img src="../assets/icons/arrow.svg" alt="" />
        </a>
        <div class="slideshow">
          <div class="slideshow-wrap">
            <SeedCard
              v-for="(item, index) in cards"
              :key="index"
              no-actions
              :type="convertCardTypeFromId(item.type)"
              :origin="mode"
              :text="item.content"
              v-model="cards[index]"
            ></SeedCard>
          </div>
          <div class="has-text-primary">
            <span class="has-text-weight-bold">{{ indexSelected }}</span> /
            {{ cards.length }}
          </div>
        </div>
        <a
          class="button is-icon"
          :class="{ 'is-disabled': cards.length == indexSelected }"
          @click="moveDeck(1)"
        >
          <img src="../assets/icons/arrow.svg" alt="" />
        </a>
      </div>
      <div class="right-side" @click="showDeleteConfirm = ''">
        <div class="card-detail">
          <div class="field is-grouped is-grouped-right">
            <router-link
              class="button is-icon has-text-primary button-close"
              to="/"
            >
              <span class="icon-cross"></span>
            </router-link>
          </div>
          <div class="avatars">
            <div class="avatar" v-for="(item, index) in user" :key="index">
              <img :src="item.avatar" alt="" v-if="item.avatar" />
            </div>
          </div>
          <div class="names-group">
            <div v-for="(item, index) in user" :key="index">
              <div class="name">{{ item.name }}</div>
              <div class="role">{{ item.position.name }}</div>
            </div>
          </div>
          <div class="date">{{ selectedCard.moment }}</div>
          <div class="tags">
            <TagGroup read white v-model="selectedCard.tags"></TagGroup>
          </div>

          <div class="content">{{ selectedCard.content }}</div>
          <div class="field buttons" v-if="mode == 'received'">
            <a
              class="button is-icon has-text-primary is-large"
              @click="favoriteCard(selectedCard.id)"
            >
              <span class="icon-favorite"></span>
            </a>
            <a
              class="button button-delete is-icon has-text-primary is-large"
              :class="{ 'is-confirm': showDeleteConfirm }"
              @click.stop="tryDelete()"
            >
              <span v-if="showDeleteConfirm" class="icon-trash-open"></span>
              <span v-if="showDeleteConfirm">Confirm delete</span>
              <span v-if="!showDeleteConfirm" class="icon-trash"></span>
            </a>
            <!-- <a class="button is-icon has-text-primary is-large">
              <span class="icon-download"></span>
            </a> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SeedCard from "@/components/SeedCard.vue";
import TagGroup from "@/components/TagGroup.vue";
import Cards from "../mixins/Cards.js";
import gsap from "gsap";

export default {
  name: "ViewSeed",
  components: {
    SeedCard,
    TagGroup
  },
  mixins: [Cards],
  data() {
    return {
      showDeleteConfirm: "",
      indexSelected: 1,
      cards: [],
      mode: "", //received or sent
      selectedCard: {
        moment: "",
        content: "",
        tags: []
      }
    };
  },
  computed: {
    origin() {
      if (this.selectedCard) {
        if (this.from_id == localStorage.getItem("userID")) {
          return "sent";
        } else {
          return "received";
        }
      }
      return "";
    },
    user() {
      let empty = [
        {
          name: "",
          position: "",
          avatar: ""
        }
      ];

      if (this.selectedCard) {
        if (this.mode == "received") {
          if (this.selectedCard.from_id) {
            let tmp = Object.assign(
              {},
              this.getUserFromId(this.selectedCard.from_id)
            );
            if (tmp.avatar != "")
              tmp.avatar = process.env.VUE_APP_ASSETS_PATH + tmp.avatar;
            return [tmp];
          } else {
            return empty;
          }
        } else {
          if (this.selectedCard.tos) {
            let users = [];
            for (let i = 0; i < this.selectedCard.tos.length; i++) {
              let tmp = Object.assign(
                {},
                this.getUserFromId(this.selectedCard.tos[i])
              );
              if (tmp.avatar != "")
                tmp.avatar = process.env.VUE_APP_ASSETS_PATH + tmp.avatar;
              users.push(tmp);
            }
            return users;
            //return this.getUserFromId(this.selectedCard.tos[0]);
          } else return empty;
        }
      } else {
        return empty;
      }
    }
    // avatar() {
    //   if (this.user && this.user.avatar != "")
    //     return process.env.VUE_APP_ASSETS_PATH + this.user.avatar;
    //   else return "";
    // }
  },
  mounted() {
    //don't like this code, we need to check if the id is a received or a sent
    let tmp = this.$store.getters["cardsreceived/list"];
    let id = this.$route.params.id;
    let found = false;
    for (let i = 0; i < tmp.length; i++) {
      if (tmp[i].id == id) {
        this.cards = tmp;
        found = true;
        this.mode = "received";
        break;
      }
    }
    if (!found) {
      tmp = this.$store.getters["cardssent/list"];
      for (let i = 0; i < tmp.length; i++) {
        if (tmp[i].id == id) {
          this.cards = tmp;
          this.mode = "sent";
          break;
        }
      }
    }

    for (let i = 0; i < this.cards.length; i++) {
      if (this.cards[i].id == id) {
        this.indexSelected = i + 1;
        this.selectedCard = this.cards[i];
        if (this.cards[i].unread) {
          //mark as read
          this.axiosRequest("put", "read-card/" + this.selectedCard.id)
            .then((response) => {
              console.log(response.data);

              this.selectedCard.unread = 0;
              this.$store.commit("cardsreceived/edit", this.selectedCard);
            })
            .catch((error) => {
              console.error(error);
            });
          break;
        }

        gsap.to(".slideshow-wrap", {
          marginLeft: -(this.indexSelected - 1) * 360 + "px",
          duration: 0
        });
      }
    }
  },
  methods: {
    moveDeck(dir) {
      console.log("movedeck ", dir);

      gsap.to(
        ".slideshow-wrap .seed-card:nth-child(" + this.indexSelected + ")",
        {
          opacity: 0,
          duration: 1
        }
      );

      gsap.to(
        ".slideshow-wrap .seed-card:nth-child(" +
          (this.indexSelected + dir) +
          ")",
        {
          opacity: 0,
          duration: 0
        }
      );

      gsap.to(
        ".slideshow-wrap .seed-card:nth-child(" +
          (this.indexSelected + dir) +
          ")",
        {
          opacity: 1,
          duration: 1
        }
      );

      this.indexSelected = this.indexSelected + dir;
      this.selectedCard = this.cards[this.indexSelected - 1];
      this.showDeleteConfirm = "";

      gsap.to(".slideshow-wrap", {
        marginLeft: -(this.indexSelected - 1) * 360 + "px",
        duration: 0.8
      });
    },
    tryDelete() {
      if (this.showDeleteConfirm == "") {
        this.showDeleteConfirm = this.selectedCard.id;
      } else {
        this.deleteCardDetail();
      }
    },
    deleteCardDetail() {
      this.deleteCard(this.selectedCard.id, () => {
        //we could try to adjust the carroussel...but would be a lot harder
        this.$router.push("/");
      });
    }
  }
};
</script>

<style scoped lang="scss">
.slideshow {
  width: 360px;
  //border: solid 1px red;
  overflow: hidden;

  .slideshow-wrap {
    height: 500px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    //border: solid 1px green;

    @include ay-mobile {
      height: 430px;
    }

    .seed-card {
      flex-shrink: 0;
      width: 280px;
    }
  }
}

.page-view-seed {
  display: flex;
  height: 100%;

  @include ay-mobile {
    margin-top: -50px;
  }

  .page-content {
    display: flex;
    height: 100%;
    width: 100%;

    @include ay-mobile {
      height: auto;
      flex-direction: column;
    }
  }

  .left-side {
    align-items: center;
    position: relative;

    @include ay-vtablet {
      padding: 3rem 0.2rem;
    }

    @include ay-mobile {
      width: 100%;
      max-width: 360px;
      margin-left: auto;
      margin-right: auto;
      padding: 0;
    }

    &:after {
      content: "";
      background: url("../assets/seed-background.svg") 50% 100% no-repeat;
      background-size: contain;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      opacity: 0.6;
    }

    .seed-card {
      margin: 20px 40px;
      transform-origin: center;
      transform: rotate(-5deg);

      @include ay-mobile {
        width: 240px;
        margin-right: 80px !important;
      }

      &:nth-child(odd) {
        transform: rotate(4deg);
      }
    }

    .button-back {
      transform: rotate(180deg);
    }
  }

  .right-side {
    @include ay-mobile {
      width: 100%;
      padding: 3rem 2rem;
    }
  }

  .card-detail {
    width: 100%;
    text-align: left;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    .avatars {
      display: flex;
      padding-left: 40px;

      .avatar {
        margin-left: -40px;
      }
    }

    .avatar {
      img {
        width: 100px;
        height: 100px;
        border-radius: 50px;
        border: solid 4px $primary;
      }
    }

    .name {
      font-weight: bold;
      font-size: 24px;
      line-height: 1em;
    }

    .role {
      font-size: 20px;
      padding-bottom: 5px;
    }

    .tags {
      padding: 10px 0 0px 0;
    }

    .content {
      word-break: break-word;
      max-height: 380px;
      overflow-y: auto;
    }

    .button {
      @include ay-mobile {
        width: 50px !important;
        height: 50px !important;
      }
    }
  }

  .button-close {
    position: absolute;
    top: 60px;

    @include ay-mobile {
      top: 10px;
    }
  }

  .button-delete {
    text-align: center;
    &.is-confirm {
      color: $danger-color !important;
      width: 240px;
    }

    .icon-trash-open {
      position: absolute;
      left: 20px;
    }
  }
}
</style>
