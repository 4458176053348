<template>
  <div class="bar-chart">
    <div class="chart-label">{{ label }}</div>
    <div class="chart">
      <div
        class="chart-fill"
        :style="{ 'background-color': color, width: valueFormat }"
      ></div>
      <div class="chart-value" v-if="value > 0">{{ value }}%</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BarChart",
  props: {
    label: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: ""
    },
    value: {
      type: Number,
      default: 0
    }
  },
  computed: {
    valueFormat() {
      return this.value + "%";
    }
  }
};
</script>

<style scoped lang="scss">
.bar-chart {
  margin-bottom: 15px;
  .chart-label {
    font-weight: bold;
    padding-bottom: 5px;
  }
  .chart {
    background: #646363;
    height: 22px;
    position: relative;
  }

  .chart-fill {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    transition: width 400ms ease;
  }

  .chart-value {
    position: absolute;
    top: 0;
    right: 20px;
    color: #ffffff;
    opacity: 0.5;
  }
}
</style>
