<template>
  <p class="control has-icons-right">
    <input
      v-bind:value="value"
      class="input"
      :type="visible ? 'text' : 'password'"
      aria-label="password"
      v-on:input="$emit('input', $event.target.value)"
    />

    <span
      class="icon toggle-visible is-small is-right"
      :class="{ 'icon-hide': visible, 'icon-eye': !visible }"
      @click="visible = !visible"
    ></span>
  </p>
</template>

<script>
export default {
  name: "PasswordField",
  props: ["value"],

  data() {
    return {
      visible: false,
    };
  },
};
</script>

<style scoped lang="scss">
.toggle-visible {
  cursor: pointer;
  pointer-events: all !important;
  color: #383737 !important;
}
</style>
