<template>
  <div class="page-plant-seed">
    <div class="pick-seed-type container" v-show="isPicking">
      <div class="field is-grouped is-grouped-right">
        <router-link class="button is-icon is-primary button-close" to="/">
          <span class="icon-cross"></span>
        </router-link>
      </div>
      <div class="wrap" :class="{ 'is-hover': mouseHover }">
        <div class="hover-wrapper">
          <SeedCard
            type="start"
            picker
            @click.native="pickType('start')"
            @mouseover.native="mouseHover = true"
            @mouseout.native="mouseHover = false"
          ></SeedCard>
        </div>

        <div class="hover-wrapper">
          <SeedCard
            type="stop"
            picker
            @click.native="pickType('stop')"
            @mouseover.native="mouseHover = true"
            @mouseout.native="mouseHover = false"
          ></SeedCard>
        </div>

        <div class="hover-wrapper">
          <SeedCard
            type="continue"
            picker
            @click.native="pickType('continue')"
            @mouseover.native="mouseHover = true"
            @mouseout.native="mouseHover = false"
          ></SeedCard>
        </div>
      </div>

      <div class="pick-seed-hint">Choose a seed</div>
    </div>

    <div class="page-content" v-show="!istypePicked">
      <div class="left-side">
        <div class="movable-card">
          <SeedCard :type="type" picker></SeedCard>
        </div>
      </div>
      <div class="right-side">
        <div class="form">
          <div class="field is-grouped is-grouped-right">
            <router-link
              href=""
              class="button is-icon has-text-primary button-close"
              to="/"
            >
              <span class="icon-cross"></span>
            </router-link>
          </div>

          <div class="title1">Prepare your seed</div>
          <div class="title2">And send it to a colleague</div>

          <div class="field">
            <label class="label is-primary-light">To:</label>
            <div class="control">
              <el-select
                v-model="to"
                multiple
                filterable
                placeholder=""
                class="to"
                @change="changeTo()"
                ref="refto"
                :multiple-limit="3"
              >
                <el-option
                  v-for="item in allUsersLessMe"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  class="select-item"
                >
                  <img
                    :src="avatar(item.avatar)"
                    alt=""
                    class="select-avatar"
                    v-if="item.avatar"
                  />
                  <span>{{ item.name }}</span>
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="field">
            <label class="label is-primary-light">Context:</label>
            <div class="control">
              <TagGroup v-model="tags"></TagGroup>
            </div>
          </div>

          <div class="field">
            <label class="label is-primary-light">Seedback:</label>
            <div class="control">
              <textarea
                class="input"
                v-model="content"
                :placeholder="$root.$options.tokens['SEED-PLACEHOLDER']"
              ></textarea>
            </div>
          </div>

          <div class="field is-grouped is-grouped-right">
            <a class="button is-outlined is-white" @click="sendSeed">Send</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SeedCard from "@/components/SeedCard.vue";
import TagGroup from "@/components/TagGroup.vue";
import gsap from "gsap";

export default {
  name: "PlantSeed",
  components: {
    SeedCard,
    TagGroup
  },
  data() {
    return {
      to: [],
      tags: [],
      content: "",
      type: null,
      mouseHover: false,
      isPicking: true
    };
  },
  computed: {
    istypePicked() {
      if (this.type) return false;
      else return true;
    },
    allUsersLessMe() {
      let users = [];
      let myId = localStorage.getItem("userID");
      let allUsers = this.$store.getters["users/list"];
      for (let i = 0; i < allUsers.length; i++) {
        if (allUsers[i].id != myId) users.push(allUsers[i]);
      }
      return users;
    }
  },
  methods: {
    changeTo() {
      console.log("change to ", this.$refs.refto);
      setTimeout(() => {
        this.$refs.refto.blur();
      }, 200);
    },
    avatar(avatar) {
      return process.env.VUE_APP_ASSETS_PATH + avatar;
    },
    pickType(type) {
      this.type = type;

      gsap.to(".pick-seed-type", {
        position: "absolute",
        duration: 0
      });

      gsap.to(".pick-seed-type", {
        opacity: 0,
        duration: 0.7,
        delay: 0.1,
        onComplete: () => {
          this.isPicking = false;
          window.scrollTo({
            top: 0,
            behavior: "smooth"
          });
        }
      });
    },
    sendSeed() {
      if (
        this.content.trim() == "" ||
        this.to.length == 0 ||
        this.tags.length == 0
      )
        return;

      let payload = {
        type: this.convertCardTypeToId(this.type),
        content: this.content,
        tags: this.tags,
        to: this.to
      };
      this.axiosRequest("post", "send-card", payload)
        .then((response) => {
          console.log(response.data);

          this.$store.commit("cardssent/unshift", response.data);

          gsap.to(".movable-card", {
            position: "absolute",
            left: "1000px",
            opacity: 0,
            duration: 0.8
          });

          gsap.to(".form", {
            opacity: 0,
            duration: 0.7
          });

          gsap.to(".right-side", {
            opacity: 0,
            marginLeft: "-200px",
            scaleX: 2,
            duration: 0.7,
            delay: 0.2,
            onComplete: () => {
              this.$router.push("/");
            }
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
};
</script>

<style lang="scss">
.page-plant-seed {
  display: flex;
  min-height: 100%;

  @include ay-mobile {
    margin-top: -100px;
  }

  .pick-seed-type {
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    background: #ffffff;
    position: relative;
    z-index: 11;
    animation-name: nointeraction;
    animation-duration: 1.5s;

    @include ay-mobile {
      display: block;
    }

    .wrap {
      display: flex;
      justify-content: center;
      align-content: center;
      width: 100%;

      @include ay-mobile {
        display: block;
        flex-direction: column;
        padding-top: 150px;
      }

      &.is-hover {
        .hover-wrapper {
          .seed-card {
            opacity: 0.3;
          }
        }
      }

      .hover-wrapper {
        perspective: 900px;

        @include ay-mobile {
          margin-top: -80px;
        }

        .seed-card {
          transition: all 400ms ease;

          &:hover {
            transform: rotateY(-24deg) rotateX(2deg);
            opacity: 1;
          }
        }
      }
    }

    .seed-card {
      margin: 0 30px;
      cursor: pointer;

      @media screen and (max-width: 1440px) {
        width: 300px;
        height: 450px;
        padding: 40px;
      }

      @include ay-desktop {
        padding: 30px;
        margin: 0 15px;
      }

      @include ay-tablet {
        width: 240px;
        margin: 0 5px;
        height: 400px;

        .type {
          font-size: 34px;
        }

        .text {
          font-size: 22px;
        }
      }

      @include ay-mobile {
        width: 100%;
        height: auto;
        margin: 20px 0;
      }
    }

    .pick-seed-hint {
      text-align: center;
      color: $primary-color;
      padding: 20px;
    }
  }

  .page-content {
    display: flex;
    min-height: 100vh;
    width: 100%;
    position: relative;
    z-index: 10;

    .left-side {
      position: relative;
      z-index: 10;

      @include ay-vtablet {
        display: none;
      }
    }

    .right-side {
      position: relative;
      z-index: 11;

      @include ay-mobile {
        padding: 2rem;

        .form {
          padding-top: 3rem;
        }
      }
    }
  }

  .title2 {
    font-size: 27px;
    font-weight: bold;
    padding-bottom: 20px;

    @include ay-mobile {
      font-size: 22px;
      line-height: 1.2em;
    }
  }

  .form {
    width: 100%;
    text-align: left;
    align-self: center;
    color: #ffffff;

    .to {
      width: 100%;
    }

    textarea {
      height: 200px;
    }
  }

  .button-close {
    //margin-bottom: 20px;
    position: absolute;
    top: 50px;
    right: 60px;

    @include ay-mobile {
      top: 20px;
      right: 20px;
    }
  }
}

.select-item {
  display: flex;
  align-items: center;

  .select-avatar {
    width: 22px;
    height: 22px;
    border-radius: 10px;
    margin-right: 4px;
    background: gray;
  }
}

.movable-card {
  display: flex;
  justify-content: center;
  align-content: center;
}

@keyframes nointeraction {
  0% {
    pointer-events: none;
  }

  100% {
    pointer-events: auto;
  }
}
</style>
