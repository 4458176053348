<template>
  <header class="header">
    <div class="container">
      <nav class="nav">
        <a @click="gotoHome()" class="nav-logotype">
          <img src="../assets/seedback-color.svg" alt="" class="logotype" />
        </a>
        <router-link to="/about" class="menu-navigation">About</router-link>

        <div class="right-menu-side">
          <a
            class="button is-large button-filters"
            aria-label="Filters"
            @click="toggleFilters()"
          >
            <span class="icon-filters"></span>
          </a>
          <a
            class="button is-icon is-large button-favorite"
            aria-label="Favorites"
            @click="toggleFavorites()"
          >
            <span
              class="icon-favorite-line has-text-primary"
              v-if="value && !value.favorites"
            ></span>
            <span class="icon-favorite has-text-primary" v-else></span>
          </a>
          <Notifications></Notifications>
          <div class="user-info" @click="drawer = true">
            <div class="avatar">
              <img :src="avatar" alt="" />
            </div>
            <div>
              <div class="name">{{ user.name }}</div>
              <div class="role">{{ user.position.name }}</div>
            </div>
          </div>
        </div>
      </nav>
    </div>

    <el-drawer title="" :visible.sync="drawer" direction="rtl">
      <div>
        <div class="user-info" @click="drawer = true">
          <div class="avatar">
            <img :src="avatar" alt="" />
          </div>
          <div>
            <div class="name">{{ user.name }}</div>
            <div class="role">{{ user.position.name }}</div>
          </div>
        </div>

        <div class="drawer-title">
          Your Seeds <span class="icon-seed"></span>
        </div>

        <div class="is-grouped button-group">
          <a
            class="button"
            :class="{ 'is-selected': origin == 'received' }"
            @click="origin = 'received'"
            >Seeds received</a
          >
          <a
            class="button"
            :class="{ 'is-selected': origin == 'sent' }"
            @click="origin = 'sent'"
            >Seeds sent</a
          >
        </div>

        <BarChart
          label="Seeds to Start"
          color="#00b69f"
          :value="percentageStart"
        ></BarChart>
        <BarChart
          label="Seeds to Continue"
          color="#68bcea"
          :value="percentageContinue"
        ></BarChart>
        <BarChart
          label="Seeds to Stop"
          color="#fd7777"
          :value="percentageStop"
        ></BarChart>
      </div>
      <div class="button-logout-wrap">
        <div>
          <router-link
            class="button is-text has-text-primary button-settings"
            to="/settings"
          >
            Settings
            <span class="icon-settings has-text-primary"></span>
          </router-link>
        </div>

        <div>
          <a
            class="button is-text has-text-primary button-logout"
            @click="logout()"
          >
            Log Out
            <span class="icon-exit has-text-primary"></span>
          </a>
        </div>
      </div>
    </el-drawer>
  </header>
</template>

<script>
import Notifications from "./Notifications.vue";
import BarChart from "@/components/BarChart.vue";
import Header from "../mixins/Header.js";

export default {
  components: { Notifications, BarChart },
  name: "AppHeader",
  props: {
    value: {
      type: Object
    }
  },
  mixins: [Header],
  data() {
    return {
      drawer: false,
      origin: "received" //sent or received
    };
  },
  computed: {
    user() {
      let userId = localStorage.getItem("userID");
      let users = this.$store.getters["users/list"];
      for (let i = 0; i < users.length; i++) {
        if (users[i].id == userId) {
          return users[i];
        }
      }
      return {
        name: "",
        position: "",
        avatar: ""
      };
    },
    avatar() {
      if (this.user.avatar) {
        return process.env.VUE_APP_ASSETS_PATH + this.user.avatar;
      } else {
        return "";
      }
    },
    percentageStart() {
      return this.getPercentageForType(1);
    },
    percentageContinue() {
      return this.getPercentageForType(3);
    },
    percentageStop() {
      return this.getPercentageForType(2);
    }
  },
  methods: {
    getPercentageForType(type) {
      let cards = this.$store.getters["cards" + this.origin + "/list"];
      let total = cards.length;
      cards = cards.filter((item) => {
        return item.type == type;
      });

      if (cards.length == 0) return 0;
      else return Math.round((cards.length / total) * 100);
    },
    gotoHome() {
      if (this.$route.name != "home") {
        this.$router.push("/");
      } else if (this.value.favorites) {
        this.value.favorites = false;
      }
    }
  }
};
</script>

<style lang="scss">
.header {
  background: #ffffff;
  position: relative;
  z-index: 120;

  @include ay-vtablet {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
  }

  .nav {
    height: 100px;
    display: flex;
    align-items: center;

    @include ay-mobile {
      height: 80px;
    }
  }

  .button-filters {
    background: url("../assets/icons/filters.svg") 50% 50% no-repeat;
    border: none;
  }

  .button-filters,
  .button-favorite {
    display: none;

    @include ay-vtablet {
      display: block;
    }
  }

  .button-favorite {
    padding-top: 18px;
  }

  .nav-logotype {
    &:after {
      display: none;
    }
  }

  .logotype {
    margin-left: 50px;
    margin-right: 70px;
    height: 40px;
    margin-top: 12px;

    @include ay-vtablet {
      margin-left: 0;
    }

    @include ay-mobile {
      max-width: 142px;
      margin-right: 0;
    }
  }

  a {
    color: $light;
    font-size: 20px;
  }

  .right-menu-side {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  .user-info {
    margin-left: 18px;
    text-align: left;
    line-height: 1.2em;
    max-width: 250px;
    padding-right: 40px;
    display: flex;
    border-left: solid 1px #d9d9d9;
    cursor: pointer;
    align-items: center;
    color: #3c3c3c;

    @include ay-vtablet {
      padding-right: 0;
      border-left: none;
    }

    @include ay-mobile {
      margin-left: 0;
    }

    .avatar {
      width: 42px;
      height: 42px;
      border-radius: 21px;
      background-color: #333;
      overflow: hidden;
      flex-shrink: 0;
      margin-right: 8px;
      margin-left: 15px;

      @include ay-mobile {
        margin-left: 5px;
      }
    }

    .name {
      font-weight: bold;
      font-size: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @include ay-vtablet {
        display: none;
      }
    }

    .role {
      opacity: 0.5;
      font-size: 15px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @include ay-vtablet {
        display: none;
      }
    }
  }

  .menu-navigation {
    @include ay-mobile {
      display: none;
    }
  }

  .router-link-active.menu-navigation {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      left: 50%;
      margin-left: -6px;
      bottom: -38px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 9px 12px 9px;
      border-color: transparent transparent $primary-color transparent;
    }
  }

  .el-drawer {
    background: #383737;
    text-align: left;

    @include ay-vtablet {
      min-width: 320px;
    }

    @include ay-mobile {
      width: 100% !important;
    }

    .drawer-title {
      border-bottom: solid 1px #eeeeee;
      font-weight: bold;
      margin-bottom: 30px;

      .icon-seed {
        margin-left: 5px;
      }
    }

    .button-group {
      margin-bottom: 30px;
      .button {
        width: 50%;
        height: 35px;
        background: #535252;
        color: #8b8a8a;
        border: none;
        font-weight: normal;
        font-size: 17px;

        &.is-selected {
          color: #000000;
          background: #ffffff;
        }
      }
    }

    .el-drawer__body {
      padding: 25px 50px;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media screen and (max-width: 1200px) {
        padding: 25px;
      }
    }

    .el-drawer__close-btn {
      color: #ffffff;
      font-size: 30px;
    }

    .user-info {
      border-left: none;
      padding-left: 0;
      margin-left: 0;
      position: absolute;
      top: 20px;
      left: 50px;
      color: #ffffff;
      pointer-events: none;

      .avatar {
        margin-left: 0;
      }
    }

    .button-logout-wrap {
      border-top: solid 1px #eeeeee;
      padding-top: 10px;
      margin-top: 20px;
      text-align: right;
    }

    .button {
      padding: 0;
      text-decoration: none !important;
      font-size: 16px;
      &:hover {
        background: transparent;
        opacity: 0.8;
        color: $primary;
      }

      .icon-exit {
        margin-left: 10px;
      }

      .icon-settings {
        margin-left: 5px;
      }
    }
  }
}
</style>
