<template>
  <main id="app">
    <template v-if="isAuth">
      <transition name="fade">
        <router-view v-if="!loading" />
      </transition>
    </template>
    <template v-else>
      <transition name="fade">
        <router-view />
      </transition>
    </template>
    <div class="no-mobile-message">
      <div class="background-effect"></div>
      <div class="wrap">
        <div class="seedback-logo"></div>
        <div class="text">
          Seedback is only available on desktop.<br />For now =)
        </div>
        <div class="footer-wrap">
          <div class="client-logo"></div>
          <div class="version">Seedback @ 2021</div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "App",
  baseURL: process.env.VUE_APP_BACKEND_API,
  data() {
    return {
      loading: true,
      intervalId: null
    };
  },
  created() {
    console.log("created APP");

    if (this.isAuth) {
      this.$store.commit("setAuthToken", localStorage.getItem("authToken"));
      this.init();
    }
  },
  destroyed() {
    if (this.intervalId) clearInterval(this.intervalId);
  },
  methods: {
    init() {
      this.axiosRequest("get", "init")
        .then((response) => {
          //console.log(response.data);
          //this.$root.$options.users = response.data.users;
          this.$root.$options.faq = response.data.faq;
          this.$root.$options.about = response.data.about;
          this.$root.$options.tokens = response.data.tokens;

          console.log(response.data.tokens);

          this.$store.commit("cardssent/set", response.data.cards.sent);
          this.$store.commit("cardsreceived/set", response.data.cards.received);
          this.$store.commit("favorites/set", response.data.favorites);
          this.$store.commit("notifications/set", response.data.notifications);
          this.$store.commit("users/set", response.data.users);

          console.log(":::", response.data.users);

          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status == 401) {
            this.logout();
          }
        });

      const seconds = 60 * 5;
      this.intervalId = setInterval(() => {
        this.axiosRequest("get", "refresh")
          .then((response) => {
            this.$store.commit("cardssent/set", response.data.cards.sent);
            this.$store.commit(
              "cardsreceived/set",
              response.data.cards.received
            );
            this.$store.commit(
              "notifications/set",
              response.data.notifications
            );
          })
          .catch((error) => {
            console.error(error);
            if (error.response.status == 401) {
              this.logout();
            }
          });
      }, seconds * 1000);
    }
  }
};
</script>

<style lang="scss">
.no-mobile-message {
  color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $primary;
  z-index: 200;
  display: none;

  @media screen and (max-width: 1200px) {
    //display: block;
  }

  .background-effect {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("assets/seed-background.svg") 50% 100% no-repeat;
    background-size: 135%;
    z-index: 1;
    opacity: 0.15;
  }

  .seedback-logo {
    height: 50px;
    background: url("assets/seedback-white.svg") 50% 50% no-repeat;
    background-size: contain;
    margin-bottom: 30px;
  }

  .client-logo {
    height: 38px;
    background: url("assets/multivision.svg") 50% 50% no-repeat;
    background-size: contain;
    margin-bottom: 15px;
  }

  .wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 10;
    position: relative;
    height: 100%;
  }

  .footer-wrap {
    position: absolute;
    bottom: 30px;
    width: 100%;
    text-align: center;
  }

  .text {
    font-size: 24px;
    color: #ffffff !important;
  }

  .version {
    opacity: 0.3;
  }
}

@media screen and (max-width: 1200px) {
  .page-login {
    //display: none !important;
  }
}

@import "@/sass/style.scss";

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
