import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import router from "./router";
import store from "./store";
import Auth from "./mixins/Auth";
import RequestHelper from "./mixins/RequestHelper";
import lang from "element-ui/lib/locale/lang/en";
import locale from "element-ui/lib/locale";
import VueGtag from "vue-gtag";

import {
  /*  DatePicker,
  TimePicker,*/
  Select,
  Option,
  Tag,
  Collapse,
  CollapseItem,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Radio,
  Checkbox,
  Drawer,
  Upload,
  Alert
  // DatePicker,
  // Notification
  /*ColorPicker,
  Button,
  Switch,

  ,
  Notification,
  Tooltip,
  MessageBox,
  Upload,
  Popover,
  Dialog,
  Tabs,
  TabPane,
  Progress*/
} from "element-ui";
import VueMasonry from "vue-masonry-css";

Vue.use(VueMasonry);

locale.use(lang);
Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.mixin(Auth);
Vue.mixin(RequestHelper);

Vue.use(Select);
Vue.use(Option);
Vue.use(Tag);
Vue.use(Checkbox);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Dropdown);
Vue.use(DropdownItem);
Vue.use(DropdownMenu);
Vue.use(Radio);
Vue.use(Drawer);
Vue.use(Upload);
Vue.use(Alert);

Vue.use(
  VueGtag,
  {
    config: { id: "G-TKS17BHNCB" }
  },
  router
);

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");
