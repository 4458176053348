<template>
  <footer class="footer">
    <div class="container">
      <img src="../assets/seedback-white.svg" alt="" class="logotype" />

      <div>Seedback © 2021</div>

      <div style="width: 190px">&nbsp;</div>
      <a class="scroll-up" @click="scrollToTop"> </a>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  }
};
</script>

<style scoped lang="scss">
.footer {
  background: $dark;
  padding: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $light;
    height: $footer-height;

    @include ay-mobile {
      flex-direction: column;
      padding-top: 2rem;
    }
  }

  .logotype {
    height: 40px;
  }

  .scroll-up {
    background: url("../assets/scroll-up.svg") 50% 50% no-repeat;
    background-size: contain;
    width: 44px;
    height: 44px;
    margin-left: 146px;
    opacity: 0;
    pointer-events: none;
    transition: all 400ms ease;
    position: fixed;
    bottom: 40px;
    right: 20px;
  }
}
</style>
