import _ from "lodash";
const createModule = ({
  name = "",
  idField = "",
  searchFields = [{ field: "name", extractor: (item) => item.name }],
  state = {},
  mutations = {},
  getters = {}
} = {}) => {
  if (!name && !idField) {
    throw new Error("Name or IdField is required");
  }
  if (_.isEmpty(idField) && !_.isEmpty(name)) {
    idField = _.camelCase(name) + "ID";
  }

  // normalizes the string, removes extra spaces and any non english chars
  const normalizeString = (value) => {
    if (Array.isArray(value)) {
      return value;
    } else if (value) {
      return value
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .trim();
    }
  };

  // two __ to avoid conflicts with "private" fields
  const indexFieldTemplate = (field) => `__${field}`;
  const indexItem = (item) => {
    const indexedItem = item;
    searchFields.map((f) => {
      const extractor = f.extractor || ((item) => item[f.field]);
      indexedItem[indexFieldTemplate(f.field)] = normalizeString(
        extractor(item)
      );
    });
    return indexedItem;
  };

  return {
    namespaced: true,
    state: {
      _items: [],
      ...state
    },
    mutations: {
      set(state, items) {
        state._items = items.map((i) => indexItem(i));
      },
      unshift(state, item) {
        state._items.unshift(item);
      },
      add(state, item) {
        state._items.push(item);
      },
      delete(state, idx) {
        state._items.splice(idx, 1);
      },
      edit(state, item) {
        //console.log(state, item, item[idField]);
        //state._items[idx] = item;
        const idx = _.findIndex(state._items, { [idField]: item[idField] });
        state._items[idx] = item;
      },
      deleteById(state, id) {
        const idx = _.findIndex(state._items, { [idField]: id });
        if (idx != -1) {
          state._items.splice(idx, 1);
        }
      },
      ...mutations
    },
    getters: {
      list: (state) => {
        return state._items;
      },
      _search: (state, getters) => (search) => {
        search = normalizeString(search);
        console.log(name || idField, search);
        return getters.list.filter((item) => {
          let result = false;
          searchFields.map((f) => {
            result =
              result ||
              _.get(item, indexFieldTemplate(f.field), "").includes(search);
          });
          return result;
        });
      },
      search: (state, getters) => (search) => {
        return getters._search(search);
      },
      byId: (state) => (id) => {
        return _.find(state._items, { [idField]: id });
      },
      ...getters
    }
  };
};

export default createModule;
