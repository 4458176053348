export default {
  computed: {
    isAuth() {
      return (
        localStorage.getItem('authToken') != null &&
        localStorage.getItem('authToken') != ''
      );
    }
  },
  methods: {
    logout() {
      localStorage.removeItem('authToken');
      localStorage.removeItem('userID');
      localStorage.removeItem('username');
      this.$store.commit('setAuthToken', '');
      this.$store.commit('setUser', {});
      window.location.href = '/login';
    }
  }
};
