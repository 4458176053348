import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
// import ForgotPassword from '../views/ForgotPassword.vue';
import PlantSeed from '../views/PlantSeed.vue';
import ViewSeed from '../views/ViewSeed.vue';
import Settings from '../views/Settings.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { requiresAuth: false },
  },
  // {
  //   path: '/forgot-password',
  //   name: 'forgot',
  //   component: ForgotPassword,
  //   meta: { requiresAuth: false },
  // },
  {
    path: '/plant',
    name: 'plant',
    component: PlantSeed,
    meta: { requiresAuth: true },
  },
  {
    path: '/seed/:id',
    name: 'seed',
    component: ViewSeed,
    meta: { requiresAuth: true },
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    meta: { requiresAuth: true },
  },
  {
    path: '/about',
    name: 'About',
    meta: { requiresAuth: false },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = !!localStorage.getItem('authToken');
  const requiresAuth =
    to.meta.requiresAuth == undefined || to.meta.requiresAuth;
  if (requiresAuth) {
    console.log('requires login');
    // this route requires auth, check if logged in
    if (!isLoggedIn) {
      console.log('not login');
      // if not, redirect to login page.
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      });
    } else {
      //is logged
      console.log('is login');
    }
  }
  next();
});

export default router;
