<template>
  <div class="options-bar" :class="{ 'is-open': value.mobileOpen }">
    <div class="container">
      <div class="mobile-title">
        <span>Filters</span>
        <div
          class="button-close button is-icon is-white has-text-primary"
          @click="resetFilters()"
        >
          <span class="icon-cross"></span>
        </div>
      </div>
      <div class="scrolled-logo"></div>
      <div class="type-toggle">
        <a
          class="button"
          :class="{ 'is-selected': value.origin == 'received' }"
          @click="changeOrigin('received')"
          >Received</a
        >
        <a
          class="button"
          :class="{ 'is-selected': value.origin == 'sent' }"
          @click="changeOrigin('sent')"
          >Sent</a
        >
      </div>

      <div class="filter-group">
        <div class="small-label">Type of Seed</div>
        <el-dropdown trigger="click" placement="bottom-start">
          <a class="el-dropdown-link">
            {{ filterType }}
          </a>
          <el-dropdown-menu slot="dropdown" class="filter-dropdown">
            <el-dropdown-item>
              <el-radio v-model="value.filters.type" label="0"
                >All Seeds</el-radio
              >
            </el-dropdown-item>
            <el-dropdown-item>
              <el-radio v-model="value.filters.type" label="1"
                >To Start</el-radio
              >
            </el-dropdown-item>
            <el-dropdown-item>
              <el-radio v-model="value.filters.type" label="3"
                >To Continue</el-radio
              >
            </el-dropdown-item>
            <el-dropdown-item>
              <el-radio v-model="value.filters.type" label="2"
                >To Stop</el-radio
              >
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <div class="filter-group context-filter-group">
        <div class="small-label">Context</div>
        <el-dropdown trigger="click" placement="bottom-start">
          <a class="el-dropdown-link">
            <TagGroup icon read v-model="value.filters.context"></TagGroup>
          </a>
          <el-dropdown-menu slot="dropdown" class="filter-dropdown">
            <TagGroup v-model="value.filters.context"></TagGroup>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <div class="filter-group">
        <div class="small-label">Time Planted</div>
        <el-dropdown trigger="click" placement="bottom-start">
          <a class="el-dropdown-link">
            {{ filterTime }}
          </a>
          <el-dropdown-menu slot="dropdown" class="filter-dropdown">
            <el-dropdown-item>
              <el-radio v-model="value.filters.time" label="0"
                >All time</el-radio
              >
            </el-dropdown-item>
            <el-dropdown-item>
              <el-radio v-model="value.filters.time" label="1"
                >Past Week</el-radio
              >
            </el-dropdown-item>
            <el-dropdown-item>
              <el-radio v-model="value.filters.time" label="2"
                >Past Month</el-radio
              >
            </el-dropdown-item>
            <el-dropdown-item>
              <el-radio v-model="value.filters.time" label="3"
                >Past Year</el-radio
              >
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <div class="right-menu">
        <a
          class="button is-icon is-large button-favorite"
          aria-label="Favorites"
          @click="toggleFavorites()"
        >
          <span
            class="icon-favorite-line has-text-white"
            v-if="!value.favorites"
          ></span>
          <span class="icon-favorite has-text-white" v-else></span>
        </a>

        <router-link class="plant-seed-button" to="/plant">
          Plant a Seed &nbsp;&nbsp;<span class="icon-seed"></span>
        </router-link>
      </div>

      <div class="buttons">
        <a
          class="button is-outlined is-white button-clear"
          @click="resetFilters()"
          >Clean Filter</a
        >
        <a
          class="button is-outlined is-white button-apply"
          @click="closeFilters()"
          >Apply filters</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import TagGroup from "@/components/TagGroup.vue";
import Header from "../mixins/Header.js";

export default {
  name: "OptionsBar",
  props: {
    value: {
      type: Object
    }
  },
  mixins: [Header],
  components: { TagGroup },
  computed: {
    filterType() {
      if (this.value.filters.type == "0") return "All Seeds";
      else {
        return "To " + this.convertCardTypeFromId(this.value.filters.type);
      }
    },
    filterTime() {
      if (this.value.filters.time == "0") return "All time";
      else if (this.value.filters.time == "1") return "Past Week";
      else if (this.value.filters.time == "2") return "Past Month";
      else return "Past Year";
    }
  },
  methods: {
    changeOrigin(newOrigin) {
      this.value.origin = newOrigin;
      localStorage.setItem("userHomeCardStack", newOrigin);
    }
  }
};
</script>

<style lang="scss">
.options-bar {
  height: $options-bar-height;
  background: $primary-color;
  text-align: left;
  position: sticky;
  top: 0;
  transition: all 400ms ease;
  z-index: 100;

  @include ay-vtablet {
    position: fixed;
    top: -1200px;
    left: 0;
    height: calc(100vh - 100px);
    width: 100%;
    opacity: 0;
    transition: all 400ms ease;

    &.is-open {
      display: block;
      top: 100px;
      opacity: 1;
    }
  }

  @include ay-mobile {
    height: calc(100vh - 80px);

    &.is-open {
      top: 80px;
    }
  }

  .mobile-title {
    color: #ffffff;
    display: none;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    font-weight: bold;
    font-size: 1.8rem;

    @include ay-vtablet {
      display: flex;
    }
  }

  .buttons {
    display: none;
    padding-top: 5rem;

    .button-clear {
      opacity: 0.5;
    }

    @include ay-vtablet {
      display: flex;
      justify-content: space-between;
      .button {
        width: 48%;
      }
    }

    @include ay-mobile {
      padding-top: 1rem;
    }
  }

  .scrolled-logo {
    background: url("~@/assets/seedback-logo-only-white.svg") 50% 50% no-repeat;
    width: 46px;
    height: 41px;
    display: none;
  }

  .filter-group {
    border-left: solid 1px #3ec7b6;
    height: 50px;
    padding-left: 30px;
    margin-left: 30px;
    min-width: 150px;
    padding-top: 5px;

    @media screen and (max-width: 1460px) {
      padding-left: 20px;
      height: auto;
    }

    @include ay-vtablet {
      border-left: none;
      border-bottom: solid 1px #3ec7b6;
      padding-left: 0;
      margin-left: 0;
      margin-bottom: 2rem;
      padding-bottom: 0;
    }

    &.context-filter-group {
      width: 220px;
      flex-shrink: 0;

      @include ay-vtablet {
        width: 100%;
      }
    }

    .el-dropdown {
      width: 100%;
    }

    .el-dropdown-link {
      width: 100%;
      color: #ffffff;
      font-size: 18px;
      position: relative;
      text-transform: capitalize;
      display: block;
      height: 38px;

      &:after {
        content: "";
        background: url("~@/assets/icons/arrow.svg") 50% 50% no-repeat;
        background-size: contain;
        filter: brightness(0) invert(1);
        position: absolute;
        transform: rotate(90deg);
        width: 16px;
        height: 24px;
        right: -10px;
        top: 0;

        @include ay-vtablet {
          right: 10px;
        }
      }
    }
  }

  .small-label {
    color: #ffffff;
    font-size: 14px;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    position: static;
    padding-right: 370px;

    @include ay-vtablet {
      display: block;
      padding: 30px;
    }
  }

  .type-toggle {
    height: 50px;
    background: #38c6b4;
    padding: 10px;
    flex-shrink: 0;

    @include ay-vtablet {
      margin-bottom: 2rem;
    }

    .button {
      height: 30px;
      background: transparent;
      border: none;
      color: #ffffff;
      font-weight: $weight-regular;
      width: 140px;

      @media screen and (max-width: 1460px) {
        width: 100px;
      }

      @include ay-vtablet {
        width: 50%;
      }

      &.is-selected {
        background: #ffffff;
        color: $primary-color;
      }
    }
  }

  .button-favorite {
    background: transparent;
    border: none;

    @include ay-vtablet {
      position: fixed;
      top: 20px;
      color: $primary;
      border: solid 1px red;
    }
  }

  .icon-favorite {
    font-size: 30px;
    color: #ffffff;
  }

  .plant-seed-button {
    background: $dark;
    color: #ffffff;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 30px;
    font-weight: bold;
    width: 300px;
    justify-content: center;

    @media screen and (max-width: 1460px) {
      width: 200px;
    }

    @media screen and (max-width: 1200px) {
      width: auto;
      font-size: 0 !important;

      .icon-seed {
        font-size: 22px;
      }
    }

    @include ay-vtablet {
      display: none;
    }
  }

  .right-menu {
    margin-left: auto;
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
  }
}

.filter-dropdown {
  width: 190px;
  margin-left: -30px;
  margin-top: 3px !important;
  box-shadow: none;
  border: none;
  padding: 10px 30px;
  border-top: solid 1px #3ec7b6;
  background: #15b69f;
  color: #ffffff;
  border-radius: 0;

  @media screen and (max-width: 1460px) {
    margin-left: -20px;
    left: 0 !important;
    padding: 10px 30px 10px 20px;
  }

  @include ay-vtablet {
    margin-left: 0;
    margin-top: 0 !important;
    width: 100%;
    padding-left: 30px;

    .tag-group {
      width: 140px;
    }

    .el-dropdown-menu__item {
      margin-bottom: 0.5rem;
    }
  }

  @include ay-mobile {
    padding: 10px 40px 10px 30px;
  }

  .popper__arrow {
    display: none;
  }

  .el-dropdown-menu__item {
    padding: 0;

    &:hover {
      background: transparent;
    }
  }

  .el-radio {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-bottom: 5px;

    .el-radio__label {
      padding-left: 0;
      color: #ffffff;
      font-size: 16px;
      font-weight: normal;
    }

    .el-radio__input {
      &.is-checked {
        .el-radio__inner {
          background: #a1e1d8;
          border-color: #ffffff;
        }
      }

      .el-radio__inner {
        border-radius: 3px;
        background: #5cccbc;
        border-color: #5cccbc;
      }

      .el-radio__inner::after {
        display: none;
      }
    }
  }
}

@include ay-mobile {
  .el-popper[x-placement^="top"] {
    border-bottom: solid 1px #3ec7b6;
    transform: translateY(10px);
  }
}
</style>
