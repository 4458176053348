import axios from "axios";

export default {
  data() {
    return {
      _requestsCount: 0
    };
  },
  computed: {
    token() {
      return localStorage.getItem("authToken");
    }
  },
  watch: {
    "$data._requestsCount"() {
      if (this.$data._requestsCount > 0) {
        this.blockUI();
      } else {
        this.releaseUI();
      }
      if (this.$data._requestsCount < 0) {
        this.$data._requestsCount = 0;
      }
    }
  },
  filters: {
    count(value) {
      if (!value) {
        return 0;
      }
      return value.length;
    }
  },
  methods: {
    convertCardTypeToId(type) {
      if (type == "start") return 1;
      else if (type == "stop") return 2;
      else if (type == "continue") return 3;
    },
    convertCardTypeFromId(type) {
      if (type == 1) return "start";
      else if (type == 2) return "stop";
      else if (type == 3) return "continue";
    },
    convertUserIdIntoName(id) {
      let allUsers = this.$store.getters["users/list"];
      for (let i = 0; i < allUsers.length; i++) {
        if (allUsers[i].id == id)
          return allUsers[i].name;
      }
      return "";
    },
    getUserFromId(id) {
      let allUsers = this.$store.getters["users/list"];
      for (let i = 0; i < allUsers.length; i++) {
        if (allUsers[i].id == id)
          return allUsers[i];
      }
      return "";
    },
    blockUI() {
      document.getElementsByTagName("html")[0].style.cursor = "wait";
      document.getElementsByTagName("html")[0].style["pointer-events"] = "none";
    },
    releaseUI() {
      document.getElementsByTagName("html")[0].style.cursor = "auto";
      document.getElementsByTagName("html")[0].style["pointer-events"] = "auto";
    },
    normalizeStr(value) {
      return value
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .trim();
    },
    // axiosRequest(method, url, data, headers = {}, responseType = 'json') {
    axiosRequest(
      method,
      url,
      data,
      { headers = {}, responseType = "json", ...rest } = {}
    ) {
      if (method === undefined) {
        console.error("axiosRequest requires at least one argument");
        return null;
      }
      if (url === undefined) {
        url = method;
        method = "GET";
      }

      console.log(process.env);
      method = method.toLowerCase();
      this.$data._requestsCount++;
      return axios({
        method,
        baseURL: process.env.VUE_APP_BACKEND_API,
        url: url,
        responseType,
        headers: {
          Authorization: "Bearer " + this.token,
          ...headers
        },
        data: data ? data : null,
        params: data && method === "get" ? data : null,
        ...rest
      }).finally(() => this.$data._requestsCount--);
    },
    showErrorMessage(error) {
      console.log(error);
      this.$notify({
        title: "",
        message: error,
        type: "error",
        position: "bottom-right",
        showClose: false,
        duration: 3000
      });
      /*
      if (typeof error == 'string') {
        this.$toasted.error(error);
      } else if (
        error.response != undefined &&
        error.response.data.errors != undefined &&
        error.response.data.errors.length > 0
      )
        this.$toasted.error(error.response.data.errors[0].message);
      else if (error.response.data.message != '') {
        this.$toasted.error(error.response.data.message);
      }*/
    },
    showSuccessMessage(msg) {
      this.$notify({
        title: "",
        message: msg,
        type: "success",
        position: "bottom-right",
        showClose: false,
        duration: 3000
      });
    }
  }
};
