<template>
  <div class="page-home" :class="{ 'was-scrolled': miniHeader }">
    <AppHeader v-model="options" />
    <OptionsBar v-model="options"></OptionsBar>

    <router-link
      v-if="cardsList.length >= 0"
      class="plant-seed-button-floating"
      to="/plant"
    >
      Plant a Seed &nbsp;&nbsp;<span class="icon-seed"></span>
    </router-link>

    <div v-show="!options.favorites">
      <div class="grid container">
        <div v-if="cardsList.length == 0" class="no-seeds">
          <div>
            <span class="icon-seed has-text-primary"></span>
          </div>
          <div class="text">
            You don't have any seeds<br />
            to water yet
          </div>
          <router-link
            class="plant-seed-button button is-dark is-large"
            to="/plant"
            >Plant a Seed</router-link
          >
        </div>

        <masonry :cols="{ default: 4, 1000: 3, 600: 1 }" :gutter="0">
          <div v-for="(item, index) in cardsList" :key="index">
            <SeedCard
              :type="convertCardTypeFromId(item.type)"
              :text="item.content"
              :origin="options.origin"
              v-model="cardsList[index]"
            ></SeedCard>
          </div>
        </masonry>

        <div class="no-more-seeds" v-if="cardsList.length > 0">
          <div>
            <span class="icon-seed has-text-primary"></span>
          </div>
          <div class="text">
            You don't have any more seeeds
          </div>
          <div class="smaller-text">
            But you can start watering those you already have
          </div>
        </div>
      </div>
    </div>

    <div class="container" v-show="options.favorites">
      <div class=" favorite-grid">
        <div class="stack">
          <div
            v-for="(item, index) in favoritesStop"
            :key="index"
            class="seed-wrapper"
          >
            <SeedCard
              :type="convertCardTypeFromId(item.type)"
              :text="item.content"
              :origin="options.origin"
              v-model="cardsList[index]"
            ></SeedCard>
          </div>
        </div>

        <div class="stack">
          <div
            v-for="(item, index) in favoritesStart"
            :key="index"
            class="seed-wrapper"
          >
            <SeedCard
              :type="convertCardTypeFromId(item.type)"
              :text="item.content"
              :origin="options.origin"
              v-model="cardsList[index]"
            ></SeedCard>
          </div>
        </div>

        <div class="stack">
          <div
            v-for="(item, index) in favoritesContinue"
            :key="index"
            class="seed-wrapper"
          >
            <SeedCard
              :type="convertCardTypeFromId(item.type)"
              :text="item.content"
              :origin="options.origin"
              v-model="cardsList[index]"
            ></SeedCard>
          </div>
        </div>
      </div>

      <div
        class="no-more-seeds"
        v-if="
          favoritesStop.length == 0 &&
            favoritesStart.length == 0 &&
            favoritesContinue.length == 0
        "
      >
        <div>
          <span class="icon-seed has-text-primary"></span>
        </div>
        <div class="text">
          Ok you don't play favorites. Yet.
        </div>
      </div>
    </div>

    <AppFooter />
    <div id="favorite-animation-background"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppFooter.vue";
import SeedCard from "@/components/SeedCard.vue";
import OptionsBar from "@/components/OptionsBar.vue";
import moment from "moment";
//import gsap from "gsap";

export default {
  name: "Home",
  components: {
    AppHeader,
    AppFooter,
    SeedCard,
    OptionsBar
  },
  data() {
    return {
      cards: {
        received: [],
        sent: []
      },
      options: {
        filters: {
          type: "0",
          context: [],
          time: "0"
        },
        origin: "received",
        favorites: false,
        mobileOpen: false
      },
      miniHeader: false,
      optionsMasonry: {
        width: 280,
        padding: {
          default: 0
        }
      }
    };
  },
  computed: {
    cardsList() {
      let cards = this.cards[this.options.origin];
      if (
        this.options.filters.type != "0" ||
        this.options.filters.time != "0" ||
        this.options.filters.context.length > 0
      ) {
        cards = cards.filter((item) => {
          let ok = true;
          if (
            this.options.filters.type != "0" &&
            item.type != this.options.filters.type
          )
            ok = false;
          if (this.options.filters.time != "0") {
            if (this.options.filters.time == 1) {
              let lastWeek = moment().subtract(5, "d"); //week
              if (moment(item.moment).isBefore(lastWeek)) ok = false;
            } else if (this.options.filters.time == 2) {
              let lastMonth = moment().subtract(30, "d"); //month
              if (moment(item.moment).isBefore(lastMonth)) ok = false;
            } else if (this.options.filters.time == 3) {
              let lastYear = moment().subtract(365, "d"); //year
              if (moment(item.moment).isBefore(lastYear)) ok = false;
            }
          }

          if (this.options.filters.context.length > 0) {
            let tmpOk = false;
            for (let i = 0; i < item.tags.length; i++) {
              for (let y = 0; y < this.options.filters.context.length; y++) {
                if (this.options.filters.context[y] == item.tags[i]) {
                  tmpOk = true;
                  break;
                }
              }
            }
            if (ok) ok = tmpOk;
          }

          return ok;
        });
      }
      return cards;
    },
    favoritesStart() {
      return this.getFavoriteByType(1);
    },
    favoritesContinue() {
      return this.getFavoriteByType(3);
    },
    favoritesStop() {
      return this.getFavoriteByType(2);
    },
    users() {
      return this.$store.getters["users/list"];
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  mounted() {
    this.cards.sent = this.$store.getters["cardssent/list"];
    this.cards.received = this.$store.getters["cardsreceived/list"];
    console.log("MOUNTED HOME", this.cards.sent);

    let cacheType = localStorage.getItem("userHomeCardStack");
    if (cacheType) {
      this.options.origin = cacheType;
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      console.log(window.scrollY);
      if (window.scrollY > 120 && !this.miniHeader) this.miniHeader = true;
      else if (window.scrollY < 80 && this.miniHeader) this.miniHeader = false;
    },
    getFavoriteByType(type) {
      let cards = this.cardsList;
      let favorites = this.$store.getters["favorites/list"];

      return cards.filter((item) => {
        if (item.type == type) {
          for (let i = 0; i < favorites.length; i++) {
            if (favorites[i].id == item.id) {
              return true;
            }
          }
        }
        return false;
      });
    }
  }
};
</script>

<style lang="scss">
#favorite-animation-background {
  background: $primary;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  height: 0;
  z-index: 10;
}

.page-home {
  padding-bottom: $footer-height;
  position: relative;
  min-height: 100%;

  @media screen and (min-width: $desktop - 200px) {
    &.was-scrolled {
      .options-bar {
        height: 70px;

        .type-toggle,
        .filter-group {
          display: none;
        }

        .scrolled-logo {
          display: block;
        }
      }

      .scroll-up {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  .plant-seed-button {
    font-size: 22px;
  }

  .plant-seed-button-floating {
    display: none;

    @include ay-mobile {
      display: block;
      background: #383737;
      color: #ffffff;
      font-weight: bold;
      padding: 5px 10px;
      width: 290px;
      margin: auto;
    }
  }

  .no-seeds {
    padding: 100px 20px;
    width: 100%;
    color: #c4c4c4;
    font-weight: $weight-medium;
    font-size: 26px;

    .icon-seed {
      font-size: 70px;
    }

    .text {
      padding: 15px 0;
      line-height: 1em;
    }

    .button {
      width: 280px;

      @include ay-mobile {
        width: 100%;
      }
    }
  }

  .grid {
    padding-top: 80px;
    padding-bottom: 80px;
    //display: flex;
    //flex-wrap: wrap;
    width: 100%;

    .seed-card {
      //margin: 0 20px 40px 20px;
      margin: 0 auto 40px auto;
    }
  }

  .no-more-seeds {
    width: 100%;
    font-weight: $weight-medium;
    color: #c4c4c4;

    .icon-seed {
      font-size: 70px;
    }

    .text {
      font-size: 26px;
      line-height: 1.2em;
    }

    .smaller-text {
      font-size: 14px;
    }
  }

  .favorite-grid {
    display: flex;
    padding: 80px 0 120px 0;
    justify-content: center;

    @include ay-mobile {
      display: block;
    }

    .stack {
      margin: 0 70px;
      position: relative;
      width: 280px;

      @include ay-vtablet {
        margin: 0 5px;
      }

      @include ay-mobile {
        width: 290px;
        margin: 0 auto;
      }

      .seed-wrapper {
        perspective: 900px;

        &:last-child {
          .seed-card {
            height: auto;
          }
        }
      }

      .seed-card {
        // position: absolute;
        // left: 0;
        //margin-top: -10vh;
        height: 175px;
        overflow: hidden;
        transition: all 400ms ease;

        &:hover {
          height: auto;
          margin-bottom: 10px;
          margin-bottom: 20px;
          transform: rotateY(-14deg) rotateX(1deg);
        }
      }
    }
  }
}
</style>
