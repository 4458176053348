<template>
  <div class="page-login">
    <div class="left-side">
      <img src="../assets/login-cards.png" alt="" />
    </div>
    <div class="right-side">
      <div class="login-box">
        <img src="../assets/seedback-white.svg" class="logotype" alt="" />
        <div class="moto">Feedback that<br />makes you grow</div>
        <form @submit.prevent="login">
          <div class="field">
            <label class="label has-text-white	">E-mail</label>
            <div class="control" :class="{ 'has-error': emailError }">
              <input
                v-model="email"
                class="input"
                type="text"
                aria-label="email"
              />
            </div>
          </div>
          <div class="field">
            <label class="label has-text-white">Password</label>
            <password-field
              v-model="password"
              :class="{ 'has-error': passwordError }"
            ></password-field>
          </div>
          <div class="field">
            <button type="submit" class="button button-submit is-fullwidth">
              Login
            </button>
          </div>

          <div class="field is-grouped">
            <div class="control">
              <label class="checkbox">
                <!-- <input type="checkbox" />
                Remember me -->
              </label>
            </div>
            <div class="control forgot-password">
              <a @click="resetPasswordModal = true">Forgot password?</a>
            </div>
          </div>
        </form>
        <br />
        <br />
        <div class="has-text-danger">{{ error }}</div>
      </div>

      <div class="client-logoype"></div>
    </div>

    <div class="reset-password modal is-active" v-show="resetPasswordModal">
      <div class="modal-background">
        <img
          src="../assets/icons/login/lock-unlocked.svg"
          alt="Lock background"
          class="lock-icon"
        />
      </div>
      <div
        class="button-close button is-icon is-white has-text-primary"
        @click="resetPasswordModal = false"
      >
        <span class="icon-cross"></span>
      </div>
      <div class="modal-content">
        <div class="login-box">
          <div class="title1">Reset Password</div>
          <p>
            Enter the email associated with your account and we'll send an email
            with instructions to reset your password
          </p>
          <form @submit.prevent="sendResetPassword">
            <div class="field">
              <label class="label has-text-white	">E-mail</label>
              <div class="control">
                <input
                  v-model="email"
                  class="input"
                  type="email"
                  aria-label="email"
                />
              </div>
            </div>
            <div class="field">
              <button type="submit" class="button button-submit is-fullwidth">
                Send Email
              </button>
            </div>
            <br />
            <div class="has-text-danger">{{ errorRecover }}</div>
          </form>
        </div>
      </div>
      <img src="../assets/seedback-white.svg" class="logotype" alt="" />
    </div>

    <div class="reset-password modal is-active" v-show="createPasswordModal">
      <div class="modal-background">
        <img
          src="../assets/icons/login/lock-unlocked.svg"
          alt="Lock background"
          class="lock-icon"
        />
      </div>
      <div
        class="button-close button is-icon is-white has-text-primary"
        @click="createPasswordModal = false"
      >
        <span class="icon-cross"></span>
      </div>
      <div class="modal-content" v-if="!success">
        <div class="login-box">
          <div class="title1">Create your password</div>
          <p>
            To finish setting your account, please enter a password.
          </p>
          <form @submit.prevent="updatePassword">
            <div class="field">
              <label class="label has-text-white">Password</label>
              <password-field v-model="newPassword"></password-field>
            </div>
            <div class="field">
              <label class="label has-text-white">Confirm password</label>
              <password-field v-model="newPasswordConfirm"></password-field>
            </div>
            <div class="field">
              <button type="submit" class="button button-submit is-fullwidth">
                Set password
              </button>
            </div>
            <br />
            <div class="has-text-danger">{{ errorChangePassword }}</div>
          </form>
        </div>
      </div>
      <div class="modal-content" v-else>
        <img src="../assets/icons/login/check.svg" alt="check" />
        <div class="title1">You’re all done!</div>
        <button
          type="button"
          class="button is-dark button-login"
          @click="createPasswordModal = false"
        >
          Login
        </button>
      </div>
      <img src="../assets/seedback-white.svg" class="logotype" alt="" />
    </div>
  </div>
</template>

<script>
import PasswordField from "../components/PasswordField.vue";
export default {
  components: { PasswordField },
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      error: "",
      errorRecover: "",
      resetPasswordModal: false,
      createPasswordModal: false,
      success: false,
      newPassword: "",
      newPasswordConfirm: "",
      errorChangePassword: "",
      emailError: false,
      passwordError: false
    };
  },
  mounted() {
    if (this.isAuth) {
      this.$router.push("/");
    }

    if (this.$route.query.token) {
      this.createPasswordModal = true;
    }
  },
  methods: {
    sendResetPassword() {
      this.axiosRequest("post", "recover", {
        email: this.email
      })
        .then((response) => {
          console.log(":", response.data);
          this.resetPasswordModal = false;
          //there is no UI for the feedback...
        })
        .catch((error) => {
          console.error(error);
          this.errorRecover =
            "Error while trying to recover, please try latter.";
        });
    },
    updatePassword() {
      if (this.newPassword != this.newPasswordConfirm) {
        this.errorChangePassword = "Passwords do not match.";
        return;
      } else if (this.newPassword.length < 8) {
        this.errorChangePassword =
          "Your new password should have at least 8 chars";
        return;
      }

      this.axiosRequest("post", "reset", {
        key: this.$route.query.token,
        password: this.newPassword
      })
        .then((response) => {
          console.log(":", response.data);
          this.success = true;
        })
        .catch((error) => {
          console.error(error);
          this.errorChangePassword =
            "Error while trying to update your password, make sure the request is not expired already.";
        });
    },
    login() {
      if (this.email.trim() == "") {
        this.emailError = true;
      }
      if (this.password.trim() == "") {
        this.passwordError = true;
      }

      if (this.email.trim() == "" || this.password.trim() == "") return;

      this.emailError = false;
      this.passwordError = false;

      this.axiosRequest("post", "auth", {
        username: this.email,
        password: this.password
      })
        .then((response) => {
          console.log(":", response.data);

          this.$store.commit("setUser", response.data);
          localStorage.setItem("authToken", response.data.token);
          localStorage.setItem("userID", response.data.username_id);
          localStorage.setItem("username", this.email);
          window.location.href = this.$route.query.redirect || "/";
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status === 401) {
            this.error = "Login failed";
          }
        });
    }
  }
};
</script>

<style lang="scss">
.page-login {
  display: flex;
  flex-direction: row;
  height: 100%;

  @include ay-vtablet {
    display: block;
    height: auto;
    min-height: 100%;
  }

  @include ay-mobile {
    margin-top: -90px;
  }

  .left-side {
    background: url("../assets/seed-background.svg") 50% 50px no-repeat;
    background-size: contain;

    @include ay-vtablet {
      display: none;
    }

    img {
      max-width: 520px;
      width: 100%;
      height: auto;
      align-self: center;
    }
  }

  .right-side {
    flex-direction: column;

    @include ay-vtablet {
      min-height: 100vh;
    }

    @include ay-mobile {
      padding: 2rem;
    }
  }

  .client-logoype {
    width: 170px;
    height: 45px;
    background: url("~@/assets/multivision.svg") 50% 50% no-repeat;
    background-size: contain;
    margin: 0 auto;
  }

  .login-box {
    width: 480px;
    margin: auto;
    color: #ffffff;
    text-align: left;

    @include ay-vtablet {
      width: 100%;
    }

    .moto {
      font-size: 38px;
      font-weight: bold;
      line-height: 1em;
      padding-bottom: 15px;
    }

    .logotype {
      height: 40px;
      margin-bottom: 10px;
    }

    .button {
      margin-top: 50px;
      color: #ffffff;
      background: $dark;
      border-color: $dark;
    }
  }
  .forgot-password {
    margin-left: auto;

    a {
      text-decoration: underline;
      color: #fff;
    }
  }
  .reset-password {
    .lock-icon {
      position: fixed;
      height: 100vh;
      right: 0;
      top: 100px;
    }
    .login-box {
      width: 400px;

      @include ay-vtablet {
        width: 100%;
        max-width: 400px;
        padding: 2rem;
      }
    }
    .button-close {
      position: fixed;
      right: 30px;
      top: 10%;
    }
    .modal-content {
      color: white;
    }

    form {
      margin-top: 20px;
    }
    .button-submit {
      margin-top: 0;
    }
    .logotype {
      height: 40px;
      position: absolute;
      bottom: 50px;
    }
    .button-login {
      width: 190px;
      margin-top: 60px;
    }
  }
}
</style>
