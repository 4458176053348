import Vue from "vue";
import Vuex from "vuex";
import createModule from "./modules/base";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {},
    token: ""
  },
  mutations: {
    setUser(state, user) {
      console.log(user);
      state.user = user;
    },
    setAuthToken(state, token) {
      state.token = token;
    }
  },
  actions: {},
  modules: {
    users: createModule({ idField: "id" }),
    cardssent: createModule({ idField: "id" }),
    cardsreceived: createModule({ idField: "id" }),
    favorites: createModule({ idField: "id" }),
    notifications: createModule({ idField: "id" })
  }
});
