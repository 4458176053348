import gsap from "gsap";

export default {
  methods: {
    toggleFavorites() {
      if (this.value.favorites) {
        gsap.to("#favorite-animation-background", {
          immediateRender: true,
          top: 0,
          bottom: "auto"
        });
      }

      gsap.to("#favorite-animation-background", {
        height: "100%",
        duration: 0.5, // seconds
        delay: 0,
        ease: "power4.out",
        onComplete: () => {
          console.log("finished anim favs");

          if (!this.value.favorites) {
            gsap.to("#favorite-animation-background", {
              immediateRender: true,
              top: "auto",
              bottom: 0
            });
          }

          this.value.favorites = !this.value.favorites;

          gsap.to("#favorite-animation-background", {
            height: 0,
            duration: 0.5
          });
        }
      });
    },
    toggleFilters() {
      this.value.mobileOpen = !this.value.mobileOpen;
    },
    closeFilters() {
      this.value.mobileOpen = false;
    },
    resetFilters() {
      this.value.mobileOpen = false;
      this.value.filters.type = "0";
      this.value.filters.context = [];
      this.value.filters.time = "0";
    }
  }
};
