export default {  
  methods: {
    favoriteCard(id) {
        let method = "post";
        let path = "create-favorite";
        let payload = {
          card_id: id
        };
  
        let favorites = this.$store.getters["favorites/list"];
        console.log(":::", favorites);
        let exists = false;
        for (let i = 0; i < favorites.length; i++) {
          if (favorites[i].id == id) {
            exists = true;
            break;
          }
        }
        if (exists) {
          method = "delete";
          path = "delete-favorite/" + id;
        }
  
        this.axiosRequest(method, path, payload)
          .then((response) => {
            console.log(response.data);
  
            if (method == "delete") {
              this.$store.commit("favorites/deleteById", id);
            } else {
              this.$store.commit("favorites/add", {
                id
              });
            }
          })
          .catch((error) => {
            console.error(error);
          });
      },
      deleteCard(id, callback) {
        this.axiosRequest("delete", "delete-card/" + id)
          .then((response) => {
            console.log(response.data);
            this.$store.commit("cards" + this.origin + "/deleteById", id);

            this.showDeleteConfirm = "";
            if(callback)
              callback();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  };
  